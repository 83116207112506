#page-footer {
  background-color: var(--background-secondary);
  border-top: 1px solid var(--border-secondary);
  padding: 6px 16px;
  font-size: 0.875rem;
  color: var(--text-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  img {
    display: inline-block;
  }

  a {
    display: inline-block;
    color: inherit;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    color: var(--color-blue);
  }

  .footer-icon-link {
    .footer-icon-link-icon {
      margin-right: 8px;
    }
    .footer-icon-link-text {
      display: none;
    }

    &:has(.footer-icon-link-text) .footer-icon-link-icon {
      margin-right: 0;
    }
  }
}
