:root {
  /* Define accent color */
  --accent-color: #152850;
  --accent-color-rgb: 21, 40, 80;

  /* Text colors */
  --text-primary: var(--accent-color);
  --text-secondary: #959595;

  /* Background colors */
  --background-primary: #f4f3f9;
  --background-secondary: #f7f7fb;
  --background-tertiary: #efefef;
  --background-quaternary: #e2e2e2;
  --background-full: #ffffff;

  /* Border colors */
  --border-primary: var(--accent-color);
  --border-secondary: #e6e6e6;
  --border-tertiary: #c7c7c7;

  --color-tooltip-background: #322f35;
  --color-tooltip-text: #f5eff7;

  --color-blue: #3966de;
  --color-blue-500: #27dde6;
  --color-red: #e86161;
  --color-red-light: #f99;
  --color-orange: #e38885;

  --color-bg-progress: #e6e6e6;
  --color-paragraph: #212529;

  --button-bg-default: #c2d0f5;
  --button-contained-bg-primary: var(--text-primary);
  --button-contained-bg-primary-hover: #0f1d3a;
  --button-contained-bg-blue: var(--color-blue);
  --button-contained-bg-blue-hover: #7a98e9;
  --button-contained-bg-blue-active: #c2d0f5;
  --button-contained-bg-red-hover: #ca5353;

  --border-radius: 10px;
  --border-form-control: 2px solid var(--accent-color);

  --font-size-heading-1: 3.375rem;
  --font-size-heading-2: 2.75rem;
  --font-size-heading-3: 2.25rem;
  --font-size-heading-4: 2rem;
  --font-size-heading-5: 1.75rem;
  --font-size-heading-6: 1.25rem;
  --font-size-normal: 1rem;
  --font-size-small: 0.8125rem;
  --font-size-button: 0.875rem;
  --font-size-body: 0.875rem;

  --alert-primary-bg: #cfe2ff;
  --alert-primary-text: #052c65;
  --alert-primary-border: #9ec5fe;
  --alert-secondary-bg: #e2e3e5;
  --alert-secondary-text: #2b2f32;
  --alert-secondary-border: #c4c8cb;
  --alert-success-bg: #d1e7dd;
  --alert-success-text: #0a3622;
  --alert-success-border: #a3cfbb;
  --alert-danger-bg: #f8d7da;
  --alert-danger-text: #58151c;
  --alert-danger-border: #f1aeb5;
  --alert-warning-bg: #fff3cd;
  --alert-warning-text: #664d03;
  --alert-warning-border: #ffe69c;
  --alert-info-bg: #cff4fc;
  --alert-info-text: #055160;
  --alert-info-border: #9eeaf9;

  scroll-behavior: smooth;
  color-scheme: light dark;
}

:root[data-theme="dark"] .glow-with-white {
  filter: drop-shadow(0 0 0.2rem white);
}

:root[data-theme="dark"] .button.button-contained.button-blue {
  background-color: #294dd6 !important;
  color: var(--text-primary) !important;
}

:root[data-theme="dark"]
  .button.button-contained.button-blue:not(:disabled):hover {
  background-color: #1b379d !important;
}

:root[data-theme="dark"]
  .button.button-contained.button-blue:not(:disabled):active {
  background-color: #4a5c98 !important;
}

:root[data-theme="dark"]
  .button.button-text.button-primary:not(:disabled):hover,
:root[data-theme="dark"]
  .button.button-outlined.button-primary:not(:disabled):hover {
  background-color: #444444;
}

:root[data-theme="dark"]
  .button.button-outlined.button-blue:not(:disabled):hover {
  background-color: #333333;
}

:root[data-theme="dark"] button:disabled,
:root[data-theme="dark"] .button.button-contained:disabled {
  background-color: rgba(62, 62, 62, 0.5) !important;
}

:root[data-theme="dark"] .button.button-text.button-blue:not(:disabled):hover {
  background-color: rgba(62, 62, 62, 0.5) !important;
}

:root[data-theme="dark"] .ui-clouds {
  display: none !important;
}

[data-theme="dark"] {
  --accent-color: #537394;
  --accent-color-rgb: 83, 115, 148;

  /* Text colors */
  --text-primary: #ffffff;
  --text-secondary: #959595;

  /* Background colors */
  --background-primary: #000000;
  --background-secondary: #262626;
  --background-tertiary: #333333;
  --background-quaternary: #404040;
  --background-full: #1a1a1a;

  /* Border colors */
  --border-primary: var(--accent-color);
  --border-secondary: #555555;
  --border-tertiary: #777777;

  --color-tooltip-background: #e6e0e9;
  --color-tooltip-text: #322f35;

  --color-bg-progress: #555555;
  --color-paragraph: #959595;

  --alert-primary-bg: #031633;
  --alert-primary-text: #6ea8fe;
  --alert-primary-border: #084298;
  --alert-secondary-bg: #161719;
  --alert-secondary-text: #a7acb1;
  --alert-secondary-border: #41464b;
  --alert-success-bg: #051b11;
  --alert-success-text: #75b798;
  --alert-success-border: #0f5132;
  --alert-danger-bg: #2c0b0e;
  --alert-danger-text: #ea868f;
  --alert-danger-border: #842029;
  --alert-warning-bg: #332701;
  --alert-warning-text: #ffda6a;
  --alert-warning-border: #997404;
  --alert-info-bg: #032830;
  --alert-info-text: #6edff6;
  --alert-info-border: #087990;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

* {
  box-sizing: border-box;
}

*:focus {
  outline-color: var(--accent-color);
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-primary);
  border: 2px solid var(--border-secondary);
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: #888888;
  border-radius: 16px;
  border: 3px solid var(--background-primary);
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}

body {
  margin: 0;
  color: var(--text-primary);
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-primary);
  overflow: hidden scroll;
}

body::-webkit-scrollbar-track {
  border-top: none;
  border-radius: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

img {
  display: block;
}

hr {
  border: 1px solid var(--accent-color);
  border-radius: 8px;
}

hr.short-hr {
  width: 50px;
  border-width: 3px;
  border-radius: 8px;
}

main {
  position: relative;
  min-height: calc(100vh - 64.9px);
  min-height: calc(100dvh - 64.9px);
}

main > div {
  min-height: inherit;
}

.message {
  display: block;
  background-color: #222222;
  color: white;
  padding: 12px 18px;
  border-radius: 5px;
  border: 1px solid #555555;
  width: 100%;
  text-align: left;
  transition: opacity 500ms ease-in-out;
}

.message .message-header {
  font-size: clamp(1rem, 2.5vw, 1.25rem);
  font-weight: 600;
  position: relative;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.message .message-body {
  padding: 16px;
  font-size: var(--font-size-body);
}

.svg-inline--fa {
  aspect-ratio: 1/1;
}

.glass {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.37);
  border-radius: 16px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  border: 1px solid var(--border-secondary);
  transition: box-shadow 300ms ease-in-out;
}

address {
  font-style: normal;
  font-size: 1rem;
  color: var(--text-primary);
}

.d-inline-block {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.bold-semi {
  font-weight: 600;
}

.link {
  text-decoration: none;
  font-size: var(--font-size-body);
  font-weight: bold;
  color: black;
}

.link:hover {
  text-decoration: underline;
}

.link.link-blue {
  color: var(--color-blue) !important;
}

.link.link-muted {
  color: var(--text-secondary) !important;
}

.section-heading {
  text-align: center;
  font-size: clamp(1.125rem, 2.5vw, 1.5rem);
  font-weight: 600;
  margin: 0 0 36px 0;
}

.icon-link {
  text-decoration: none;
  font-size: var(--font-size-body);
  color: black;
}

.icon-link.icon-link-blue {
  color: var(--color-blue) !important;
}

.icon-link span {
  margin-left: 6px;
}

.icon-link:hover span {
  text-decoration: underline;
}

.orange-color {
  color: var(--color-orange) !important;
}

.bg-white {
  background-color: var(--background-full);
}

.rounded {
  border-radius: 16px;
}

.position-relative {
  position: relative;
}

input.form-control {
  display: inline-block;
  border: var(--border-form-control);
  border-radius: 40px;
  font-size: 0.875rem;
  width: 100%;
  padding: 0 12px;
  height: 40px;
  font-weight: 600;
  box-sizing: border-box;
  accent-color: var(--accent-color);
  background-color: var(--background-full);
  color: var(--text-primary);
  outline-color: var(--accent-color);
  transition: box-shadow 300ms;
}

input.form-control:focus-visible {
  box-shadow: 0 0 0.4em var(--accent-color);
}

input.form-control:disabled {
  opacity: 0.6 !important;
}

.disabled {
  cursor: unset;
  opacity: 0.6;
  pointer-events: none;
}

input.form-control:focus,
.button:focus {
  outline: 1px solid var(--accent-color);
}

input.form-control::placeholder {
  opacity: 0.6;
}

.form-label {
  color: var(--text-primary);
  font-size: 1rem;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  align-items: start;
}

.form-label a {
  color: inherit;
}

input[type="checkbox"].form-check {
  margin-top: 4px;
  transform: scale(1.5);
  background-color: var(--background-full);
  accent-color: var(--color-blue);
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-7 {
  margin-bottom: 72px;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-7 {
  margin-top: 72px;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 8px;
}

.me-2 {
  margin-right: 16px;
}

.me-3 {
  margin-right: 24px;
}

.me-4 {
  margin-right: 32px;
}

.me-5 {
  margin-right: 40px;
}

.me-6 {
  margin-right: 48px;
}

.me-7 {
  margin-right: 72px;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: var(--text-primary);
}

.text-muted {
  color: var(--text-secondary);
}

.d-none {
  display: none;
}

.d-grid {
  /* Change display to grid */
  display: grid;
}

.d-grid-mobile {
  display: none !important;
}

.d-block-mobile {
  display: none;
}

.d-none-mobile {
  display: block;
}

.article {
  overflow: hidden;
}

#project-header-mobile {
  display: none;
}

.progress-container {
  background-color: var(--color-bg-progress);
  border: 0.5px solid var(--border-secondary);
  width: 100%;
  height: 14px;
  border-radius: 16px;
  overflow: hidden;
}

.progress-fill {
  width: 0%;
  height: 100%;
  background-color: var(--color-blue);
  border-radius: 16px;
  transition: width 300ms cubic-bezier(0, 0, 0.15, 1);
}

.progress-text {
  margin-top: 8px;
  text-align: center;
}

.dropdown {
  position: relative;
  display: inline-block;
  width: max-content;
  white-space: nowrap;
}

.dropdown .dropdown-indicator {
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--color-red);
  position: absolute;
  transform: translateY(-50%);
  left: 8px;
  top: 50%;
  z-index: 5;
}

.dropdown.dropdown-contain {
  display: grid;
  width: 100% !important;
}

.dropdown.dropdown-contain button {
  width: 100%;
}

.dropdown .dropdown-children {
  transform: translateY(-10px) scale(0.6);
  opacity: 0;
  visibility: hidden;
  z-index: 16;
  position: relative;

  transition: transform 300ms, opacity 300ms, visibility 300ms;
}

.dropdown .dropdown-children.show {
  transform: translateY(-0) scale(1) !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.dropdown .dropdown-chevron-button .button-icon-right,
.dropdown .dropdown-chevron-button .button-icon-middle {
  transform: rotate(0);
  transition: transform 300ms;
}

.dropdown .dropdown-chevron-button.rotate .button-icon-right,
.dropdown .dropdown-chevron-button.rotate .button-icon-middle {
  transform: rotate(-180deg);
}

.dropdown .dropdown-list {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  background-color: var(--background-secondary);
  background-color: var(--background-full);
  transform: translateY(100%);
  padding: 4px;
  width: max-content;
  border-radius: 8px;
  border: 1px solid var(--border-primary);
}

.dropdown .dropdown-list.open-to-bottom {
  right: 0;
  left: unset;
  transform: translate(0%, 100%);
}

.dropdown .dropdown-list.open-to-left {
  left: 90%;
  transform: translate(-100%, 100%);
}

.dropdown .dropdown-list.open-to-right {
  left: unset;
  right: -10%;
  transform: translate(0, 100%);
}

.dropdown .dropdown-list .dropdown-list-item {
  position: relative;
  text-align: left;
  padding: 8px 16px;
  font-size: 0.875rem;
  font-weight: normal;
  cursor: pointer;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 14px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  margin-bottom: 2px;
  border-radius: 10px;
  overflow-wrap: break-word;
  word-wrap: break-all;
  hyphens: auto;
  transition: background-color 300ms;
}

.dropdown .dropdown-list .dropdown-list-item.with-icon {
  grid-template-columns: 14px 1fr 14px;
}

.dropdown .dropdown-list .dropdown-list-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.dropdown .dropdown-list button.dropdown-list-item {
  border: none;
  background-color: transparent;
}

.dropdown .dropdown-list .dropdown-list-item:hover {
  background-color: var(--background-tertiary);
}

.dropdown .dropdown-list .dropdown-list-item:active {
  background-color: var(--background-quaternary);
}

.dropdown .dropdown-list .dropdown-list-item.selected {
  background-color: var(--color-blue);
  color: white;
  transition: box-shadow 0.2s, background-color 300ms;
}

.dropdown .dropdown-list .dropdown-list-item.selected:hover {
  background-color: var(--accent-color);
}

.dropdown .dropdown-list .dropdown-list-item.selected:active {
  background-color: var(--accent-color);
  box-shadow: 0 0 0.2em var(--color-blue);
}

.two-pieces {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 32px;
}

.two-pieces img {
  max-width: 500px;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.img-fluid {
  max-width: 100%;
  width: 100%;
  display: block;
}

.show-mobile {
  display: none;
}

.hide-mobile {
  display: block;
}

.hide-mobile-inline {
  display: inline !important;
}

@media screen and (max-width: 1024px) {
  #project-header-desktop {
    display: none !important;
  }

  #project-header-mobile {
    display: flex !important;
  }

  .project-mobile-sidebar-button {
    display: block !important;
  }

  #projects {
    grid-template-columns: repeat(2, 100%) !important;
    overflow: hidden;
  }

  #projects.show-project {
    transform: translateX(-100%);
    overflow: unset;
  }

  .dot-collection {
    display: none !important;
  }

  #search-results {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  .landing-jumbotron {
    border-radius: 16px;
  }
}

@media screen and (max-width: 1085px) {
  .navbar {
    grid-template-columns: auto auto 1fr auto !important;
    grid-template-areas:
      "navbar-button navbar-brand-wrapper navbar-extra-content"
      "navbar-collapse navbar-collapse navbar-collapse" !important;
    column-gap: 10px !important;
  }

  .navbar.navbar-user-auth {
    grid-template-areas:
      "navbar-button navbar-brand-wrapper search-form navbar-extra-content"
      "navbar-collapse navbar-collapse navbar-collapse navbar-collapse" !important;
  }

  .navbar .navbar-collapse-button-wrapper {
    /* Show nav button wrapper. */
    display: block !important;
  }

  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link {
    margin-bottom: 4px;
  }

  .navbar .navbar-collapse {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    left: 0;
    top: 72px;
    background-color: var(--background-secondary);
    z-index: 15 !important;
    padding: 0;
    transition: grid-template-rows 400ms, padding 400ms;
  }

  .navbar .navbar-collapse.show {
    grid-template-rows: 1fr;
    padding: 32px 0;
  }

  .navbar .navbar-nav-container {
    min-height: 0;
    max-height: max-content !important;
    display: grid;
    gap: 16px;
  }

  .navbar .navbar-nav {
    flex-direction: column;
    justify-content: flex-start !important;
    max-width: 100% !important;
    width: 100%;
  }

  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link.active::after {
    display: none;
  }

  .navbar .navbar-brand-wrapper img {
    height: 100%;
  }

  .navbar .navbar-extra-content {
    margin-left: auto;
  }

  .navbar .navbar-nav .navbar-nav-item:last-child {
    margin-bottom: 0;
  }

  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link {
    font-size: 1.125rem !important;
    padding: 8px 24px;
    border-radius: 4px;
  }

  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link:hover,
  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link:focus {
    background-color: var(--background-tertiary);
  }

  .navbar .navbar-nav .navbar-nav-item .navbar-nav-link.active {
    background-color: var(--color-blue);
    color: #ffffff;
  }

  footer #footer-inner {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .h1 {
    font-size: 3.125rem;
  }

  .h2 {
    font-size: 2.5rem;
  }

  .h3 {
    font-size: 2.125rem;
  }

  .h4 {
    font-size: 1.875rem;
  }

  .h5 {
    font-size: 1.625rem;
  }

  .h6 {
    font-size: 1.375rem;
  }

  .two-pieces {
    grid-template-columns: 1fr;
  }

  .d-grid-mobile {
    display: grid !important;
  }

  .d-none-mobile {
    display: none !important;
  }

  .d-block-mobile {
    display: block !important;
  }

  .task-details-body-main-info {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .task-info-tables-holder {
    grid-template-columns: 1fr !important;
    grid-auto-rows: auto !important;
  }

  .file-data-container {
    grid-template-columns: 100% !important;
  }

  #tasks-charts {
    grid-template-columns: 100% !important;
  }

  #search-results {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  .ui-clouds {
    max-width: 60% !important;
  }

  .file-chart-secion-inner {
    display: block !important;
    grid-template-columns: unset;
    grid-auto-rows: unset;
  }

  .file-chart-secion-inner .file-chart-section-left {
    margin-bottom: 32px;
  }

  .file-chart-secion-inner
    .file-chart-section-left
    .file-charts-columns-holder {
    display: flex !important;
    flex-wrap: wrap;
    max-width: 100% !important;
    max-height: 200px !important;
    overflow: auto;
  }

  .sidebar {
    top: 79.5px !important;
    height: calc(100vh - 79.5px) !important;
    height: calc(100dvh - 79.5px) !important;
  }

  .sidebar.no-project {
    top: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .contact-us-inner {
    grid-template-columns: 1fr !important;
    max-width: 512px;
    margin: 0 auto;
  }

  .contact-us-inner .contact-us-inner-right {
    display: none;
  }

  .project-tasks-container {
    --task-width: 150px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .rounded-sm-0 {
    border-radius: 0 !important;
  }

  .button.button-only-icon-tablet {
    padding: 0 16px !important;
    width: 40px;
  }

  .button.button-only-icon-tablet .button-children {
    display: none !important;
  }

  .container-header .container-header-inner.three-pieces {
    grid-template-columns: max-content 1fr max-content !important;
  }

  .project-tasks-container {
    --task-width: unset !important;
    max-width: 100% !important;
    grid-template-columns: 1fr !important;
  }

  .task-holder {
    display: grid !important;
    grid-template-areas: "project-item-icon project-item-name-holder project-item-menu";
    grid-template-columns: auto 1fr auto;
    padding-inline: 32px !important;
    border-radius: 100px !important;
    align-items: center;
  }

  .task-holder .project-item-menu {
    display: grid;
    place-items: center;
    position: static !important;
    right: unset !important;
    top: unset !important;
  }

  .task-name-holder {
    display: grid;
    grid-template-areas:
      "project-item-name project-item-time"
      "project-item-size project-item-time";
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    text-align: left !important;
    column-gap: 12px;
  }

  .task-name-holder .task-name {
    text-align: left !important;
  }

  .project-item-icon {
    width: 42px !important;
    height: 42px !important;
  }

  .purchased-task {
    transform: unset !important;
    left: 50px !important;
  }
}

@media screen and (max-width: 670px) {
  .hide-when-single {
    display: none !important;
  }

  .profile-page-configure {
    grid-template-columns: 1fr !important;
    border: none !important;
    text-align: center;
  }

  .profile-page-configure h6 {
    margin-top: 16px;
  }

  .task-control-buttons-holder {
    display: grid !important;
    row-gap: 32px !important;
  }
}

@media (width < 548px) {
  .project-information-details table tr {
    border-bottom: 1px solid var(--border-secondary);
  }

  .project-information-details table tr td {
    padding: 8px 0 !important;
  }

  .project-information-details table tr td:last-child {
    text-align: right;
  }
}

@media screen and (max-width: 480px) {
  #search-results {
    grid-template-columns: 1fr !important;
  }

  .hide-mobile-inline {
    display: none !important;
  }

  dl.description-list dt {
    margin-left: 0 !important;
  }

  dl.description-list dd {
    margin-right: 0 !important;
  }
}

@media screen and (width <= 425px) {
  .show-mobile {
    display: block !important;
  }

  .hide-mobile {
    display: none !important;
  }

  .navbar .navbar-brand-wrapper {
    margin-left: 0;
    text-align: center;
  }

  .navbar {
    display: grid !important;
  }

  footer #footer-inner {
    grid-template-columns: 1fr !important;
  }

  .h1 {
    font-size: 2.5rem !important;
  }

  .h2 {
    font-size: 2.125rem !important;
  }

  .h3 {
    font-size: 1.75rem !important;
  }

  .h4 {
    font-size: 1.5rem !important;
  }

  .h5 {
    font-size: 1.375rem !important;
  }

  .h6 {
    font-size: 1.25rem !important;
  }

  .learn-three-heading,
  .file-page-info-cards {
    grid-template-columns: 1fr !important;
  }

  .project-details {
    grid-template-columns: 1fr !important;
  }

  .navbar.navbar-user-auth {
    grid-template-columns: auto auto 1fr auto !important;
    grid-template-areas:
      "navbar-brand-wrapper search-form navbar-extra-content"
      "navbar-collapse navbar-collapse navbar-collapse";
    column-gap: 10px !important;
  }

  #messages-container {
    right: 0 !important;
    max-width: 100% !important;
    padding: 0 16px;
  }

  .task-details-body-main-info {
    grid-template-columns: 1fr !important;
  }

  #page-footer {
    padding: 32px 16px;
    flex-direction: column;
    align-items: flex-start;
  }

  #page-footer
    .footer-icon-link:has(.footer-icon-link-text)
    .footer-icon-link-icon {
    margin-right: 8px !important;
  }

  #page-footer .footer-icon-link .footer-icon-link-text {
    display: inline;
  }

  .button.button-only-icon-mobile {
    padding: 0 16px !important;
    width: 40px;
  }

  .button.button-only-icon-mobile .button-children {
    display: none !important;
  }
}
