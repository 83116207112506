@keyframes ripple-animation {
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.3;
  }

  100% {
    width: 400px;
    height: 400px;
    opacity: 0;
  }
}

@keyframes live-shadow-blue {
  0%,
  50%,
  100% {
    box-shadow: 0 0 0.4em inset var(--color-blue);
  }

  25%,
  75% {
    box-shadow: 0 0 0.6em inset var(--color-blue);
  }
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: var(--text-primary);
  border: var(--border-form-control);
  background-color: transparent;
  padding: 0 24px;
  min-width: 40px;
  width: max-content;
  height: 40px;
  font-size: var(--font-size-button);
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  outline: none !important;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  line-height: 0;

  transition: color 300ms, background-color 300ms, box-shadow 300ms;

  &:disabled {
    opacity: 0.6 !important;
    cursor: unset !important;
  }

  .ripple {
    position: absolute;
    border-radius: 50%;
    background-color: var(--accent-color);
    animation: ripple-animation 0.5s linear;
    pointer-events: none;
    transform: translate(-50%, -50%);
  }

  // On focus
  &.button-primary:focus-visible {
    box-shadow: 0 0 0.4em inset var(--accent-color);
  }

  &.button-blue:focus-visible {
    box-shadow: 0 0 0.4em inset var(--color-blue);
    animation: live-shadow-blue 10000ms ease-in-out infinite;
  }

  &.button-red:focus-visible {
    box-shadow: 0 0 0.4em inset var(--color-red);
  }

  // Contained variant button
  &.button-contained {
    border: none;
    color: white;

    &.button-primary {
      background-color: var(--button-contained-bg-primary);

      &:not(:disabled):hover {
        background-color: var(--button-contained-bg-primary-hover);
      }
    }

    &.button-blue {
      background-color: var(--button-contained-bg-blue);

      &:not(:disabled):hover {
        background-color: var(--button-contained-bg-blue-hover);
      }

      &.button:active,
      &.button-selected {
        background-color: var(--button-contained-bg-blue-active);
        color: var(--text-primary);
      }
    }

    &.button-red {
      background-color: var(--color-red);

      &:not(:disabled):hover {
        background-color: var(--button-contained-bg-red-hover);
      }
    }

    .ripple {
      background-color: white;
    }
  }

  // Outlined variant button
  &.button-outlined {
    border-width: 2px;
    border-style: solid;

    &.button-primary {
      border-color: var(--border-primary);
      color: var(--text-primary);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--accent-color);
      }
    }

    &.button-blue {
      border-color: var(--color-blue);
      color: var(--color-blue);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--color-blue);
      }
    }

    &.button-red {
      border-color: var(--color-red);
      color: var(--color-red);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--color-red);
      }
    }
  }

  // Text variant button
  &.button-text {
    border: none;
    background-color: transparent;

    &.button-primary {
      color: var(--text-primary);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--accent-color);
      }
    }

    &.button-blue {
      color: var(--color-blue);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      &.button:active,
      &.button-selected {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--color-blue);
      }
    }

    &.button-red {
      color: var(--color-red);

      &:not(:disabled):hover {
        background-color: var(--background-quaternary);
      }

      .ripple {
        background-color: var(--color-red);
      }
    }
  }

  // Disabled button
  &:disabled {
    background-color: var(--background-quaternary) !important;
    color: var(--text-primary) !important;
    border-color: #959595 !important;
  }

  // Button sizes
  &.button-large {
    font-size: var(--font-size-normal);
    padding: 0 20px !important;
  }

  &.button-small {
    font-size: 0.75rem !important;
    padding: 0 6px !important;
    min-width: 32px;
    height: 32px;

    .button-icon {
      font-size: 0.9375rem !important;
    }
  }

  &.button-single-icon {
    min-width: 40px;
    width: 40px;
    height: 40px;
    padding: 0 !important;

    &.button-small {
      min-width: unset !important;
      width: 32px !important;
      height: 32px !important;
    }
  }

  &.button-iconed-left {
    padding-left: 16px !important;
  }

  &.button-iconed-right {
    padding-right: 16px !important;
  }

  &.button-only-icon-when-tablet,
  &.button-only-icon-when-mobile {
    .button-children {
      display: block;
    }
  }

  .button-icon {
    font-size: 1rem;
  }
}
