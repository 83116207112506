.horizontal-rule {
  // border: 1px solid var(--border-secondary);
  border-width: 1px;
  border-style: solid;
  border-color: var(--border-secondary);
  margin: 24px 0;

  &.horizontal-rule-primary {
    border-color: var(--border-primary);
  }
}
