#page-header {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

#page-header-skeleton {
  display: grid;
  grid-template-columns: 0.4fr 1fr 0.4fr;
  gap: 16px;
  padding: 16px;
  height: 64.9px;
  background-color: var(--background-secondary);
}
