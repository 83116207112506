.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--text-primary);
  line-height: 1.2;
}

.h1 svg,
.h2 svg,
.h3 svg,
.h4 svg,
.h5 svg,
.h6 svg {
  margin-right: 8px;
}

.h1 {
  font-size: var(--font-size-heading-1);
}

.h2 {
  font-size: var(--font-size-heading-2);
}

.h3 {
  font-size: var(--font-size-heading-3);
}

.h4 {
  font-size: var(--font-size-heading-4);
}

.h5 {
  font-size: var(--font-size-heading-5);
}

.h6 {
  font-size: var(--font-size-heading-6);
}