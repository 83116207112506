.search-bar {
  position: relative;

  .search-bar-tail-spin-container {
    position: absolute;
    transform: translateY(-50%);
    right: -18px;
    top: 50%;
    height: max-content;
  }
}
