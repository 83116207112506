.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 16px;

  &.container-expand {
    max-width: 100%;
    width: 100%;
  }

  &.container-x-llarge {
    max-width: 1440px;
    width: 100%;
  }

  &.container-large {
    max-width: 1040px;
    width: 100%;
  }

  &.container-medium {
    max-width: 840px;
    width: 100%;
  }

  &.container-small {
    max-width: 420px;
    min-height: 600px;
    width: 100%;
  }

  .small-contianer-holder {
    min-height: calc(100vh - 64.9px - 32px);
    min-height: calc(100dvh - 64.9px - 32px);
  }
}

.small-contianer-holder {
  display: grid;
  place-items: center;
  min-height: inherit;
}
