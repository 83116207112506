/* Search Icon styles. It will live on left side of search input. It will only visible when searchIcon parametere true on SearchInputProps props. */
.search-icon {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 8px;
  color: var(--text-primary);
  cursor: pointer;
  font-size: 1.125rem;
  background-color: transparent;
  border: none;
  padding: 0;
  z-index: 5;
}

.search-input-container {
  --timing: 300ms;

  position: relative;
  width: 100%;

  input:disabled,
  input:focus,
  input:valid,
  input:not(:placeholder-shown) {
    & ~ .search-input-label {
      transform: translateY(0);
      top: -8px !important;
      font-size: 12px;
      color: var(--text-primary);
      padding: 0 4px;
    }
  }

  .search-input-label {
    position: absolute;
    transform: translateY(-50%);
    left: 16px;
    top: 50%;
    color: var(--text-secondary);
    font-size: var(--font-size-body);
    pointer-events: none;
    user-select: none;
    z-index: 10;
    background-color: var(--background-full);

    transition: color var(--timing), top var(--timing), transform var(--timing),
      font-size var(--timing), padding var(--timing);

    &.has-search-icon {
      left: 50px;
    }
  }

  .search-input-searching-animation {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
