.navbar {
  background-color: var(--background-secondary);
  color: var(--text-primary);
  padding: 10px 16px;
  border-bottom: 1px solid var(--border-secondary);
  display: grid;
  justify-content: space-between;
  align-items: center;
  position: relative;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "navbar-brand-wrapper navbar-collapse navbar-extra-content";

  &.navbar-user-auth {
    grid-template-columns: auto 1.5fr minmax(80px, 250px) auto;
    grid-template-areas: "navbar-brand-wrapper navbar-collapse search-form navbar-extra-content";
    column-gap: 8px !important;
    justify-items: center;
  }

  .navbar-brand {
    font-size: 1.25rem;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
  }

  .navbar-brand-wrapper {
    grid-area: navbar-brand-wrapper;
  }

  .navbar-collapse {
    width: 100%;
    grid-area: navbar-collapse;
  }

  .navbar-nav {
    max-width: 550px;
    display: flex;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    column-gap: 12px;
    height: 100%;
    justify-content: space-around;

    .navbar-nav-item {
      display: grid;
      align-items: center;
      position: relative;

      .navbar-nav-link {
        color: var(--text-primary);
        text-decoration: none;
        font-weight: 600;
        font-size: var(--font-size-body);
        border-radius: 24px;
        padding: 10px 24px;
        transition: color 300ms, background-color 300ms;

        &.active {
          background-color: var(--background-quaternary);
        }
      }
    }
  }

  .navbar-extra-content {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    grid-area: navbar-extra-content;
  }

  .navbar-collapse-button-wrapper {
    /* Nav collapse button will start with 'display: none' whenever page is load. */
    display: none;

    grid-area: navbar-button;

    .navbar-collapse-button {
      /* Make nav button a square. */
      width: 50px;
      height: 50px;

      /* Remove background color so it ships with navbar background color. */
      background-color: transparent;

      /* Remove border and round edges. */
      border: none;
      border-radius: 50%;

      /* Make the button bigger. */
      font-size: 1.375rem;

      /* Show click cursor on hover. */
      cursor: pointer;

      svg {
        transform: rotate(0deg);
        transition: all 300ms ease-in-out;
      }

      svg.animated {
        animation-name: rotate_menu_button;
        animation-duration: 300ms;
      }
    }
  }

  .search-form {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    grid-area: search-form;

    .search-bar {
      width: inherit;
    }
  }
}

@keyframes rotate_menu_button {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.hidden {
  display: none;
}
