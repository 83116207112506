#offline-page-container {
  text-align: center;
  height: calc(100vh - 64.9px);
  height: calc(100dvh - 64.9px);
  display: flex;
  justify-content: center;
  align-items: center;

  .offline-image {
    max-width: 300px;
    width: 100%;
    height: auto;
  }
}
